// @ts-strict-ignore
import React, { type FC } from 'react';
import { useRouter } from 'bb/app/router';
import { DeprecatedLinkButton, Section } from 'bb/common';
import { Block } from 'bb/common/components';
import { Grid } from 'bb/common/grid';
import { Image } from 'bb/common/images';
import { SM_100VW_LG_50VW } from 'bb/common/images/config';
import { type ImageData } from 'bb/common/images/types';
import css from 'bb/discovery/start/start.module.scss';
import { Header, Text } from 'bb/i18n';
import { Box, Row, Stack } from 'bb/ui';
import promoCss from './studentPromo.module.scss';

type StudentPromoProps = {
    image: ImageData;
};

export const StudentPromo: FC<StudentPromoProps> = ({ image }) => {
    const { routes } = useRouter();

    return (
        <Section>
            <Grid>
                <Block classNames={promoCss.backgroundImage}>
                    <Box
                        padding="small"
                        bgColor="primary-white"
                        className={promoCss.box}
                    >
                        <Stack justifyContent="center">
                            <Text as="h2" t="student:studentPromoHeader" center>
                                <Header
                                    type="secondary"
                                    as="span"
                                    marginBottom="none"
                                    size="medium"
                                />
                                <Header
                                    center
                                    marginTop="none"
                                    type="primary"
                                    as="span"
                                    size="medium"
                                />
                            </Text>
                            <Row marginTop="medium" justifyContent="center">
                                <DeprecatedLinkButton
                                    route={routes.student}
                                    variant="primary"
                                >
                                    <Text t="student:studentPromoReadMore" />
                                </DeprecatedLinkButton>
                            </Row>
                        </Stack>
                    </Box>
                </Block>
                <Image
                    data-optimizely="student-promo-image"
                    className={css.subHeroImage}
                    img={image}
                    config={SM_100VW_LG_50VW}
                />
            </Grid>
        </Section>
    );
};
