import { useEffect } from 'react';
import { usePost } from 'bb/api/browser';
import { type ErrorResponse, type Status } from 'bb/api/browser/types';
import { type HalLink } from 'bb/common/types';

export function useFetchContentByHal<Response>(halLink: HalLink): {
    result: Response;
    error: ErrorResponse;
    status: Status;
} {
    const { result, error, status, execute } = usePost<Response, HalLink>(
        '/api/follow'
    );
    useEffect(() => {
        if (halLink?.href) {
            execute(halLink);
        }
    }, [halLink, execute]);

    return { result, error, status };
}
