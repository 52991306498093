import React, { type FC } from 'react';
import { BookListCarousel } from 'bb/discovery/components/BookCarousel';
import { BookListCarouselSection } from 'bb/discovery/components/BookListCarouselSection';
import { useFetchStartPageBookList } from 'bb/discovery/start/useFetchStartPageBookList';
import { Text } from 'bb/i18n';
import { type StartPageAppView } from '../../types';

type StartAppViewProps = {
    appView: StartPageAppView;
    fetchOnClient: boolean;
};

export const StartAppView: FC<StartAppViewProps> = ({
    appView,
    fetchOnClient
}) => {
    const [bookListResponse] = useFetchStartPageBookList(fetchOnClient);
    const bookList = appView?.items[0] || bookListResponse;

    return (
        <BookListCarouselSection
            heading={{ children: bookList?.title }}
            subHeading="start:appViewText"
            data-optimizely="start-booklist-section"
        >
            {bookList ? (
                <BookListCarousel bookList={bookList} showHeader={false} />
            ) : (
                <Text>Loading books</Text>
            )}
        </BookListCarouselSection>
    );
};
