import React, { type FC } from 'react';
import { useAccountStore } from 'bb/account';
import { useRouter } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { BookBeatHeaderAnimatedChildren } from 'bb/common';
import { CtaButton } from 'bb/common/components/buttons/CtaButton';
import { PaddedBox } from 'bb/common/components/PaddedBox';
import { Header, Text } from 'bb/i18n';
import { Box, Display, Row, Stack } from 'bb/ui';
import { ProductDependingText } from './ProductDependingText';

interface HeroBottomProps {
    ctaTrackingName?: string;
}

export const HeroBottom: FC<HeroBottomProps> = observer(
    ({ ctaTrackingName }) => {
        const { isLoggedIn } = useAccountStore();
        const { router } = useRouter();
        // Winback param
        const { wb } = router.query;
        return (
            <Display on={isLoggedIn === false && !wb}>
                <PaddedBox
                    as="section"
                    bgColor="primary-purple"
                    data-optimizely="hero-bottom-section"
                >
                    <Row flexWrap="wrap" alignItems="stretch">
                        <Box>
                            <Stack justifyContent="center">
                                <BookBeatHeaderAnimatedChildren>
                                    <Header
                                        block
                                        as="h2"
                                        type="secondary"
                                        size="large"
                                        t="start:bottomHeroHeaderSecondary"
                                        center
                                        data-optimizely="hero-bottom-title"
                                    />
                                    <Header
                                        block
                                        as="h2"
                                        type="primary"
                                        size="large"
                                        marginTop="none"
                                        center
                                    >
                                        <ProductDependingText
                                            standardText="start:bottomHeroHeaderPrimary"
                                            oneSubscriptionProductText="start:bottomHeroHeaderPrimaryOneProduct"
                                        />
                                    </Header>
                                </BookBeatHeaderAnimatedChildren>

                                <Text
                                    t="start:bottomHeroText"
                                    center
                                    marginTop="small"
                                />
                                <CtaButton
                                    name={
                                        ctaTrackingName ||
                                        'startpage-hero-bottom'
                                    }
                                    variant="secondary"
                                    marginTop="small"
                                    data-optimzely="hero-bottom-cta"
                                />
                            </Stack>
                        </Box>
                    </Row>
                </PaddedBox>
            </Display>
        );
    }
);
