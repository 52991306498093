import React from 'react';
import { useVerifyLoggedIn } from 'bb/account';
import { useRouter } from 'bb/app/router';
import { type Route } from 'bb/app/router/types';
import { useTranslation } from 'bb/i18n/translationUtils';
import { Box, Flex, SpinnerIcon, type MarginSize } from 'bb/ui';
import { Button } from 'bb/ui/Button';
import { getCampaignCodeFromQuery, match } from 'bb/utils';
import { CampaignButton } from '..';
import { AccountButton } from '../AccountButton';
import { RedeemButton } from '../RedeemButton';
import { TrialDaysButton } from '../TrialDaysButton';
import { type SignupCtaProps } from '../types';
import { WinbackButton } from '../WinbackButton';
import css from './ctaButton.module.scss';

export type CtaButtonProps = {
    withCampaign?: boolean;
    withWinback?: boolean;
    code?: string;
    marginTop?: MarginSize;
    accountRoute?: Route;
    suppressHydrationWarning?: boolean;
} & SignupCtaProps;

export type CtaButtonMatchFnData = {
    withWinback: boolean | undefined;
    withWinbackParam: boolean | undefined;
    withCampaign: boolean | undefined;
    campaignCode: string | undefined;
    isLoggedIn: boolean | undefined;
    accountRoute: Route | undefined;
    translationsReady: boolean;
};

const isWaiting = ({ isLoggedIn, translationsReady }: CtaButtonMatchFnData) =>
    /**
     * If `isLoggedIn` is undefined it means that we are waiting for a response.
     */
    isLoggedIn === undefined || !translationsReady;

const hasWinbackParam = ({
    withWinback,
    withWinbackParam
}: CtaButtonMatchFnData) => Boolean(withWinback && withWinbackParam);
const hasCampaignParam = ({
    isLoggedIn,
    withCampaign,
    campaignCode
}: CtaButtonMatchFnData) =>
    Boolean(!isLoggedIn && withCampaign && campaignCode);

const hasAccountRoute = ({ accountRoute, isLoggedIn }: CtaButtonMatchFnData) =>
    isLoggedIn && accountRoute;

const isLoggedOut = ({ isLoggedIn }: CtaButtonMatchFnData) => !isLoggedIn;

const isLoggedInWithCampaignParam = ({
    withCampaign,
    campaignCode,
    isLoggedIn
}: CtaButtonMatchFnData) => Boolean(isLoggedIn && withCampaign && campaignCode);

export const CtaButton = ({
    name,
    variant = 'primary',
    marginTop,
    withCampaign,
    withWinback,
    code,
    accountRoute,
    suppressHydrationWarning,
    children,
    fluid = false,
    ...restProps
}: CtaButtonProps) => {
    const { router } = useRouter();
    const { data: { isLoggedIn } = {} } = useVerifyLoggedIn();
    /**
     * Translations for the cta namespace is usually loaded client-side.
     * To avoid a flash of untranslated text, we wait for the translations to be ready
     * and we render the fallbackButton until we have the translations.
     */
    const { ready: translationsReady } = useTranslation(['cta']);

    const withWinbackParam = Boolean(router.query?.wb);
    const campaignCode = getCampaignCodeFromQuery(router.query) || code;
    const campaignQuery = campaignCode
        ? {
              campaign: campaignCode
          }
        : undefined;

    const fallbackButton = (
        <Button
            className={css.fallbackButton}
            startAdornment={<SpinnerIcon />}
            data-testid="cta-button-loading"
        />
    );

    const dataLoaded = isLoggedIn !== undefined && translationsReady;

    return (
        <Box
            {...(dataLoaded ? { 'data-testid': 'logged-in-data-loaded' } : {})}
            marginTop={marginTop}
        >
            <Flex justifyContent="center">
                {match<CtaButtonMatchFnData>({
                    withWinback,
                    withWinbackParam,
                    withCampaign,
                    campaignCode,
                    isLoggedIn,
                    accountRoute,
                    translationsReady
                })
                    .on(isWaiting, () => fallbackButton)
                    .on(hasWinbackParam, () => (
                        <WinbackButton fluid={fluid} name={name} />
                    ))
                    .on(hasCampaignParam, () => (
                        <CampaignButton
                            name={name}
                            variant={variant}
                            campaignQuery={campaignQuery}
                            fluid={fluid}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...restProps}
                        >
                            {children}
                        </CampaignButton>
                    ))
                    .on(isLoggedInWithCampaignParam, () => (
                        <RedeemButton
                            fluid={fluid}
                            name={name}
                            variant={variant}
                            campaignQuery={campaignQuery}
                        >
                            {children}
                        </RedeemButton>
                    ))
                    .on(hasAccountRoute, () => (
                        <AccountButton
                            fluid={fluid}
                            name={name}
                            /**
                             * accountRoute will always be defined here since
                             * the match function checks for it. However, TypeScript
                             * cannot properly infer this, so we need to cast it like
                             * this.
                             */
                            accountRoute={accountRoute as Route}
                            variant={variant}
                        />
                    ))
                    .on(isLoggedOut, () => (
                        <TrialDaysButton
                            fluid={fluid}
                            suppressHydrationWarning={suppressHydrationWarning}
                            name={name}
                            variant={variant}
                            fallbackButton={fallbackButton}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...restProps}
                        />
                    ))
                    .otherwise(() => null)}
            </Flex>
        </Box>
    );
};
