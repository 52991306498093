// @ts-strict-ignore
import React, { type FC } from 'react';
import { useMarket } from 'bb/app';
import { type ImageData } from 'bb/common/images/types';
import { getMarketConfig } from 'bb/config/marketConfig';
import { SignupBottom } from './Signup/SignupBottom';
import { StudentPromo } from './StudentPromo/StudentPromo';

type PromoBlockProps = {
    images: ImageData[];
};
export const PromoBlock: FC<PromoBlockProps> = ({ images }) => {
    const { market } = useMarket();
    const promoImage = images?.find(
        (img) => img.position === 'promoBlockImage'
    );
    const studentPromoImage = images?.find(
        (img) => img.position === 'studentPromoImage'
    );
    const marketConfig = getMarketConfig(market);
    return marketConfig?.showStudentPromo ? (
        <StudentPromo image={studentPromoImage} />
    ) : (
        <SignupBottom image={promoImage} />
    );
};
