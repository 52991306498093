import React, { type FC } from 'react';
import { observer } from 'bb/app/stores';
import { AmountPerMonth, LimitaryText, useTranslation } from 'bb/i18n';
import { useSubscriptionStore } from 'bb/subscription';
import { type MarginSize } from 'bb/ui';

type ProductDependingTextProps = {
    standardText: string;
    oneSubscriptionProductText: string;
    className?: string;
    marginTop?: MarginSize;
    center?: boolean;
    suppressHydrationWarning?: boolean;
    testId?: string;
};

export const ProductDependingText: FC<ProductDependingTextProps> = observer(
    ({
        standardText,
        oneSubscriptionProductText,
        center,
        marginTop,
        className,
        testId,
        suppressHydrationWarning
    }) => {
        const { t } = useTranslation(['common']);
        const subscriptionStore = useSubscriptionStore();
        const lowestProductPrice =
            subscriptionStore.subscriptionProducts.lowestPrice;

        const oneSubscriptionProduct =
            subscriptionStore.subscriptionProducts.products.length === 1;

        const { trialHours } = subscriptionStore.subscriptionProducts;
        const trialText = `${trialHours} ${t('common:hours', {
            periodLength: trialHours
        })}`;
        return (
            <LimitaryText
                center={center}
                marginTop={marginTop}
                className={className}
                testId={testId}
                suppressHydrationWarning={suppressHydrationWarning}
                t={
                    oneSubscriptionProduct
                        ? oneSubscriptionProductText
                        : standardText
                }
            >
                <AmountPerMonth amount={lowestProductPrice} />
                {trialText}
            </LimitaryText>
        );
    }
);
