import { useEffect, useState } from 'react';
import { type HalLink } from 'bb/common/types';
import { useFetchAppViews } from '../hooks/useFetchAppViews';
import { useFetchContentByHal } from '../hooks/useFetchContentByHal';
import { type AppViewResponse, type BookListResponse } from '../types';

export const useFetchStartPageBookList = (
    doFetch: boolean
): [BookListResponse] => {
    const [appViewHal, setAppViewHal] = useState<HalLink>({
        href: '',
        method: 'GET'
    });
    const [bookListHal, setBookListHal] = useState<HalLink>({
        href: '',
        method: 'GET'
    });

    const skipAppViews = !doFetch || Boolean(appViewHal.href);

    const { data: appViewsResult } = useFetchAppViews({
        enabled: !skipAppViews
    });
    const { result: appViewResult } =
        useFetchContentByHal<AppViewResponse>(appViewHal);
    const { result: bookListResult } =
        useFetchContentByHal<BookListResponse>(bookListHal);

    useEffect(() => {
        if (appViewsResult) {
            setAppViewHal({
                href:
                    appViewsResult?._embedded?.views[0]?._links?.self?.href ||
                    '',
                method: 'GET'
            });
        }
    }, [appViewsResult]);

    useEffect(() => {
        if (appViewResult) {
            const bookList = appViewResult?._embedded?.items?.find(
                (item) => item.type === 'BookList'
            );
            setBookListHal({
                href: bookList?._links?.self?.href || '',
                method: 'GET'
            });
        }
    }, [appViewResult]);

    return [bookListResult];
};
