import { type Color } from 'bb/style/types';

type LandingPage = {
    color?: Color;
    showCampaignCodeOnPage?: boolean;
};

export const PAGE = {
    full: { color: 'tertiary-yellow' },
    giftcard: { color: 'tertiary-purple' },
    pretty: { color: 'tertiary-red' },
    promocode: { color: 'tertiary-green' },
    standard: { color: 'tertiary-red' }
} as const;

export type PageConfigType = keyof typeof PAGE;

export const getPageConfig = (type: PageConfigType): LandingPage => PAGE[type];

export const ILLUSTRATIONS = {
    'tertiary-green': {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/5bH9jkQytBx7qr1WQh0pjC/4b7425724dc42f402a1a2c2eac2de67f/illustration_green_zoom.webp',
        altText: 'Listen and read. Choose between over 500 000 titles',
        title: 'StarIllustration'
    },
    'tertiary-purple': {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/78dfNH4CWeKmQ8U6xo9Hcx/99e802396f884ac5182d719564d1484f/illustration_purple_zoom.webp',
        altText: 'Listen and read. Choose between over 500 000 titles',
        title: 'StarIllustration'
    },
    'tertiary-red': {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/HbQ0wl723QiTruBm7dHFM/8f528cb2fb50af45b66ba07816ab6fc6/illustration_red_zoom.webp',
        altText: 'Listen and read. Choose between over 500 000 titles',
        title: 'StarIllustration'
    },
    'tertiary-yellow': {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/3PPxhuWj7O0Z4TSOyXOMlU/fc884b7321262a049f5b8e4ba53181ff/illustration_yellow_zoom.webp',
        altText: 'Listen and read. Choose between over 500 000 titles',
        title: 'StarIllustration'
    }
} as const;
