import React from 'react';
import { useIsLoggedIn } from 'bb/account';
import { LinkButton, type LinkButtonProps } from 'bb/common/LinkButton';
import { useTranslation } from 'bb/i18n';
import { useCtaTracker } from 'bb/tracker/hooks/useCtaTracker';

export type WinbackButtonProps = {
    name: string;
} & Omit<LinkButtonProps, 'route'>;

export const WinbackButton = ({ name, ...restProps }: WinbackButtonProps) => {
    const { t } = useTranslation('cta');
    const isLoggedIn = useIsLoggedIn();
    const tracker = useCtaTracker();

    return (
        <>
            {isLoggedIn ? (
                <LinkButton
                    {...restProps}
                    route="subscription"
                    onClick={() => tracker.winbackCta(name)}
                    data-testid="loggedin-winback-button"
                >
                    {t('cta:winback')}
                </LinkButton>
            ) : (
                <LinkButton
                    {...restProps}
                    route="login"
                    onClick={() => tracker.winbackCta(name)}
                    data-testid="loggedout-activate-button"
                >
                    {t('cta:loginActivate')}
                </LinkButton>
            )}
        </>
    );
};
