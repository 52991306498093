import React, { type FC, useId } from 'react';
import { observer } from 'bb/app/stores';
import { HeaderSection } from 'bb/discovery/components/HeaderSection';
import { SubscriptionProducts } from 'bb/discovery/start/components/SubscriptionProducts';
import { useTranslation } from 'bb/i18n';
import { useSubscriptionStore } from 'bb/subscription';

const SubscriptionsContent = () => {
    const { t } = useTranslation();
    const labelId = useId();

    return (
        <HeaderSection
            title="start:subscription"
            heading="start:subscriptionHeader"
            subHeading="start:subscriptionText"
            data-optimizely="subscriptions-section"
        >
            <SubscriptionProducts
                buttonText={t('cta:fallback')}
                labelledby={labelId}
            />
        </HeaderSection>
    );
};

export const Subscriptions: FC = observer(() => {
    const subscriptionStore = useSubscriptionStore();

    if (subscriptionStore.subscriptionProducts?.products?.length > 1) {
        return <SubscriptionsContent />;
    }
    return null;
});
