import React from 'react';
import classnames from 'classnames';
import { Section, BookBeatHeader, type BookBeatHeaderProps } from 'bb/common';
import { Block } from 'bb/common/components';
import { CtaButton } from 'bb/common/components/buttons/CtaButton';
import { PaddedBox } from 'bb/common/components/PaddedBox';
import { Grid } from 'bb/common/grid/Grid';
import { Image } from 'bb/common/images';
import css from 'bb/discovery/start/start.module.scss';
import { type ImageConfigProps } from 'bb/discovery/start/types';
import { useDaysPeriod } from 'bb/i18n';
import { Stack } from 'bb/ui/Flex/Stack';
import { ProductDependingText } from '../ProductDependingText';

type SignupTopProps<TElement extends React.ElementType = 'h2'> =
    Partial<ImageConfigProps> & {
        trialDays: number;
        headerProps?: Partial<BookBeatHeaderProps<TElement>>;
    };

const IMAGE_CONFIG = {
    sizes: [
        {
            screenMaxWidth: 320,
            width: 320
        },
        {
            screenMaxWidth: 912,
            width: 400
        },
        {
            screenMaxWidth: 4096,
            width: 650
        }
    ]
};

export const SignupTop = (({
    image,
    trialDays,
    headerProps = {}
}: SignupTopProps<'h2'>) => {
    const { as = 'h2', ...restHeaderProps } = headerProps;
    const { period } = useDaysPeriod(trialDays);
    return (
        <Section data-optimizely="signup-top-section">
            <Grid>
                <PaddedBox>
                    <Stack alignItems="center" justifyContent="center">
                        <BookBeatHeader
                            {...restHeaderProps}
                            as={as}
                            textKey="start:header"
                            additionalValues={{ period }}
                            withAnimation
                            testId="signup-header"
                        />
                        <div data-optimizely="signup-top-text">
                            <ProductDependingText
                                standardText="start:offer"
                                oneSubscriptionProductText="start:offerOneProduct"
                                center
                                marginTop="extraSmall"
                                testId="signup-product-text"
                                suppressHydrationWarning
                            />
                        </div>
                        <div data-optimizely="signup-top-cta">
                            <CtaButton
                                suppressHydrationWarning
                                name="startpage-top"
                                marginTop="small"
                            />
                        </div>
                    </Stack>
                </PaddedBox>
                <Block
                    classNames={css.heroBlockImage}
                    data-optimizely="signup-top-image-block"
                    bgColor="primary-purple"
                >
                    <Stack className={classnames(css.heroBlock)}>
                        <Image
                            className={css.heroImage}
                            img={image}
                            config={IMAGE_CONFIG}
                            data-optimizely="signup-top-image"
                            loading="eager"
                        />
                    </Stack>
                </Block>
            </Grid>
        </Section>
    );
}) as <TElement extends React.ElementType>(
    props: SignupTopProps<TElement>
) => JSX.Element;
