import React, { type FC } from 'react';
import { Section, BookBeatHeader } from 'bb/common';
import { Block } from 'bb/common/components';
import { CtaButton } from 'bb/common/components/buttons/CtaButton';
import { Grid } from 'bb/common/grid/Grid';
import { Image } from 'bb/common/images';
import { SM_100VW_LG_50VW } from 'bb/common/images/config';
import css from 'bb/discovery/start/start.module.scss';
import { type ImageConfigProps } from 'bb/discovery/start/types';
import { Stack } from 'bb/ui';
import { ProductDependingText } from '../ProductDependingText';

export const SignupBottom: FC<ImageConfigProps> = ({ image }) => (
    <Section>
        <Grid>
            <Block padding="large" bgColor="tertiary-red">
                <Stack justifyContent="center">
                    <BookBeatHeader textKey="start:bottomSignupHeader" />
                    <ProductDependingText
                        standardText="start:offer"
                        oneSubscriptionProductText="start:offerOneProduct"
                        marginTop="extraSmall"
                        center
                    />
                    <CtaButton name="startpage-bottom" marginTop="small" />
                </Stack>
            </Block>
            <Image
                className={css.subHeroImage}
                img={image}
                config={SM_100VW_LG_50VW}
            />
        </Grid>
    </Section>
);
